<template>
<footer id="foot">
<div class="blackinkeye-shape blackinkeye-shape-special"><svg preserveAspectRatio="none" viewBox="0 0 284 26"><path fill="#222" d="M283 28l-3-27s-4 6-4 13c-4 9-10 2-12-2l-2-9c-2 1 0 5 0 7 1 8-13 16-10 5 0-8-3-11-3-2l-1 7c-6 7-9-3-12-1-5 5-10 7-10-1 1-1 1-4-1-3-2 0 0 4-1 6s-6 1-7-1v-8l1-7c-2-5-5-1-5 2 0 4 2 9 1 13-12 13-8 2-8-6 0-4 1-6-3-6l1 6v6c-2 5-4-3-6-1-2 1-5 10-7 3 0-2 2-7 0-9-4-2-2 8-2 8-4 4-5-3-7-4-7 4-5 10-11 0l1-7c-1-4-5-1-5 2 4 13-6 11-9 2 0-2 2-11-1-11-5 6-2 25-9 9-1-3-2-1-2 1 0 10-7 3-7-5 0-2 2-8-2-8 3 7-11 30-11 12 0-2 1-8-1-9-3-2-2 7-2 8 0 9-9 17-9 3 1-3-1-9-4-5-1 2 1 4 1 6l-2 5c-1 1-2 3-5 3l-4-5c-2-1-1 1-2 2-11 7-9-4-9-4l-3 5s-6 1-7-1c-2-5 3-12 0-17-3-4-4 3-4 5 1 5 2 9 0 13-1 3-6 6-8 3s2-10 1-14c0-4-3-3-3 1 1 2 3 10 0 11-4 2-3-7-7 0-2 4-5 3-5-1l1-5c-1-2-2-4-4-1 0 15-4 2-7 4-3 5-6 6-9 0-2-3 1-8-1-10-4-4-3 5-3 7-2 14-11 0-9-7 0-1 0-10-3-5v9c0 10-12 8-12 1 0-4 2-8 1-11S3 0 2 2c-2 4 0 9 0 12 1 4-2 11-2 11v3z"/></svg></div>
<div style="background-color: #222;">
<div class="container">
	  <div class="row">
		<div class="col-sm-4" style="z-index: 18">
		  <img loading="lazy" alt="logo" style="width:15%; float:left;" src="./../assets/sitelogo1-1.png"	 width="100" height="100">
		  <p style="margin-bottom: 1.5em;">© 2019-{{ new Date().getFullYear() }} – <a href="https://blackinkeye.tk/">BlackInkEye</a> | {{ $t("message.main.5.s1.text") }}</p>
		</div>
		<div class="col-xs-6 col-sm-2">
		  <h2 class="bigfoot">{{ $t("message.main.5.s2.header") }}</h2>
			<ul class="footer-links">
            <li><a href="/site/privacy-policy">{{ $t("message.main.5.s2.link1") }}</a></li>
			<li><a href="/sitemap.xml">{{ $t("message.main.5.s2.link2") }}</a></li>
			<li><a href="/site/press">{{ $t("message.main.5.s2.link3") }}</a></li>
          </ul>
		</div>
<div class="col-xs-6 col-sm-2">
		  <h2 class="bigfoot">{{ $t("message.main.5.s3.header") }}</h2>
			<ul class="footer-links">
			<li><a href="/site/FAQs">{{ $t("message.main.5.s3.link1") }}</a></li>
			<li><a href="/site/RSS-Feeds">{{ $t("message.main.5.s3.link2") }}</a></li>
          </ul>
		</div>
		<div class="col-sm-4">
		  <h2 class="bigfoot">{{ $t("message.main.5.s4.header") }}</h2>
	<a class="roundbuttsfoot twitterbutt" href="https://twitter.com/BlackInkEye/"><svg width="37" height="30" viewBox="0 0 512 512"><path d="M459 152c1 252-230 398-459 265 56 6 112-10 156-44-47-1-85-31-98-72 16 1 33 1 47-2-50-21-90-54-84-105 14 8 30 13 47 14-46-31-61-93-32-141 51 64 129 106 216 110C242 68 353 4 431 81c24-4 47-13 67-25-8 24-25 45-46 58 21-3 41-8 60-17-14 21-32 40-53 55z" fill="#fff"/></svg></a>
	<a class="roundbuttsfoot youtubebutt" href="https://www.youtube.com/channel/UCD9jHo85hvfgf0cvhYBdNgQ"><svg width="37" height="30" viewBox="0 0 576 512"><path d="M500 67c-136-5-290-21-425 8-87 40-88 332 0 362 136 5 310 18 426 0 87-39 93-340-1-370zM232 338V175l143 81z" fill="#fff"/></svg></a>
	<a class="roundbuttsfoot vkbutt" href="https://vk.com/blackinkeye"><svg width="37" height="30" viewBox="0 0 576 512"><path d="M545 118c-9-25-89-23-102-5-17 39-35 135-100 138-21-22-10-96-10-133-1-35-124-27-124-8 23 11 42 165 13 166-20 0-69-73-98-157-3-37-105-35-105-6 39 127 121 299 272 303 79 2 24-95 58-96 56 3 80 95 128 96 25 0 88 7 79-25-133-177-59-84-11-273z" fill="#fff"/></svg></a>
	<a class="roundbuttsfoot discordbutt" href="https://discord.gg/P9bytDR"><svg width="37" height="30" viewBox="0 0 448 512"><path d="M271 272c-37-5-28-56 0-57 38 5 27 56 0 57zm-93-57c-38 4-27 56 0 57 37-5 28-56 0-57zM448 53v459c-64-57-44-38-119-108l14 48H52c-28 0-52-24-52-53V53C0 24 24 0 52 0h344c28 0 52 24 52 53zm-73 242c0-82-37-149-37-149-37-27-72-27-72-27l-3 4c43 14 63 33 63 33-64-36-137-45-206 0 0 0 21-20 67-34l-2-3s-35 0-72 27c0 0-37 67-37 149 0 0 22 38 78 39l17-21c-32-10-45-30-45-30 67 40 151 31 200 0 0 0-13 21-46 31l17 20c56-1 78-39 78-39z" fill="#fff"/></svg></a>
	<a class="roundbuttsfoot plusbutt" href="https://blackinkeye.tk/site/Official_Pages"><svg width="37" height="30" viewBox="0 0 448 512"><path d="M416 208H272V64c-4-50-95-40-96 0v144H32c-50 4-40 95 0 96h144v144c4 50 95 40 96 0V304h144c50-4 40-95 0-96z" fill="#fff"/></svg></a>
	  </div>
	</div>
</div>
</div>
<!--<div style="margin-top:240px;" class="col">
	  <div class="boxed">
	  <div class="row">
		<div class="col-md">
		  <blockquote id="quoteText"></blockquote>
		</div>
		<div style="margin-bottom:150px;" class="col-sm-4" id="quoteimg">
		  <img loading="lazy" alt="logo"  src="https://placehold.co/400" width="400">	
 </div>
	</div>
	</div>
</div>	 -->
<!-- <footer>
      <p>{{ footerText }}</p>
  </footer> -->
</footer>
</template>

<script>
export default {
    data() {
        return{
            footerText: 'Copyright BlackInkEye 2021'
        }
    }
}
</script>

<style>
#foot{
	z-index: 1;
}
.roundbuttsfoot {
  display: inline-block;
  line-height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50%;
  text-shadow: 0 0 40px #000d;
  transition: 0.3s ease;
  margin: 0px 4px 0px 0px;
}
.roundbuttsfoot svg {
	margin-bottom: -11px;
}
.roundbuttsfoot:hover {
  opacity: 0.85;
  color:#FFFFFF;
  transform:rotate(10deg) scale(1.15,1.15) translateY(-10px);
  transition: 0.3s ease;	
}
.footer-links {
 list-style:none;
 overflow:hidden;
 padding-left: 0;
 margin-top:-12px;
}
.footer-links li {
 margin-top:12px;
 display:block;
 line-height:1
}
.footer-links a {
 font-size:16px;
 color:#a3a3a3;
 letter-spacing:-.01em;
 line-height:15px;
 text-decoration:none;
 transition:all .2s
}
.footer-links a:hover {
 color:#6F5EED
}
.bigfoot {
 font-size:30px;
 color:#FFF;
}
</style>