<template>
<div class="qwerybya">
<div class="col" id="itsnotending" style="padding-bottom: 150px;">
<section class="boxed ttttt4t">
<div class="row">
<div class="col-sm abstrct"><img loading="lazy" src="./../../assets/checkthis.webp" alt="Abstract image to show something cool"></div>
<div class="col-sm"><h2 id="itslookslikeending-heading-title">{{ $t("message.main.3.notending") }}</h2>
<h3 id="youwantsomethingmore-heading-title">{{ $t('message.main.3.evenmore') }}</h3><p class="json-line" style="font-size: 17px; color: #777; margin-top: 20px;">{{ $t("message.main.3.evenmore1") }}</p><ul class="morelistr" id="morelisttext" style="list-style: none;">
<li><svg width="37" height="30" viewBox="0 0 512 512"><path d="M327 185C479 361 176 592 44 468c-59-59-59-156 0-215 77-56 109 28 58 56-76 193 280 108 158-76-15-34 43-81 67-48zM468 44C267-74 62 194 185 327c14 23 88-19 57-57-92-196 261-228 168-67-49 34 3 105 58 56 59-59 59-155 0-215z" fill="#ff23bb" /></svg>{{ $t("message.main.3.content1") }}</li>
<li><svg width="37" height="30" viewBox="0 0 448 512"><path d="M312 320h136V56c0-13-11-24-24-24H24C11 32 0 43 0 56v400c0 13 11 24 24 24h264V344c0-13 11-24 24-24zm8 160V352h128c-12 43-80 128-128 128z" fill="#ff23bb" /></svg>{{ $t("message.main.3.content2") }}</li>
<li><svg width="37" height="30" viewBox="0 0 576 512"><path d="M194 150C-9 172 2 199 136 329c-43 200-13 197 152 111 177 102 183 73 152-111 153-136 126-151-58-179-82-187-106-167-188 0z" fill="#ff23bb" /></svg>{{ $t("message.main.3.content3") }}</li></ul> <div class="readmore"><a href="https://blackinkeye.tk/site/Extra" target="_self" style="margin-top: 40px;">{{ $t("message.main.3.exploremore") }}</a></div></div></div>
</section>
</div></div>
</template>