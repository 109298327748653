import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
  
const routes: Array<RouteRecordRaw> = [  
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/sofia",
    name: "Sofia",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Sofia.vue")
  },
  {
    path: "/aifos",
    name: "Aifos",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Aifos.vue")
  },
  { 
    path: '/:pathMatch(.*)*',
    component: () =>
      import(/* webpackChunkName: "about" */ "../404.vue")
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
