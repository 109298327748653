<template>
<div id="sofiahello">
<div class="col">
<div class="blackinkeye-shape"><svg style="height:371px" viewBox="0 0 1000 100" preserveAspectRatio="none"><path fill="#0a0a0a" d="M0 6V0h1000v100L0 6z" /></svg></div>
<section class="boxed">
<div class="row" style=" padding-bottom: 150px;">
<div class="col-md" id="birbold"><img loading="lazy" alt="avatar" id="sofiaavatar" src="./../../assets/sofia1.webp"></div>
<div class="col-md"><h2 id="hello-heading-title">{{ $t("message.main.2.hello") }}</h2><h2 id="aboutme-heading-title">{{ $t("message.main.2.aboutme") }}</h2><h2 id="mynameis-heading-title">{{ $t("message.main.2.mynameis") }}</h2>
<div style="margin-top: 20px;" id="zigzagline"><svg viewBox="0 0 2000 68"><path d="M0 43v24l42-42 43 42 42-42 42 42 42-42 43 42 42-42 42 42 42-42 20 19V20L380 1l-42 42-42-42-42 42-43-42-42 42-42-42-42 42L42 1" fill="#d92e77" /></svg></div>
<p class="json-line" style="margin-top: 20px; font-size: 18px">{{ $t("message.main.2.aboutmetext") }}</p>
<div style="margin-top: 40px;">
<a class="roundbutts twitterbutt" href="https://twitter.com/SofiaTheBirb/"><svg width="37" height="30" viewBox="0 0 512 512"><path d="M459 152c1 252-230 398-459 265 56 6 112-10 156-44-47-1-85-31-98-72 16 1 33 1 47-2-50-21-90-54-84-105 14 8 30 13 47 14-46-31-61-93-32-141 51 64 129 106 216 110C242 68 353 4 431 81c24-4 47-13 67-25-8 24-25 45-46 58 21-3 41-8 60-17-14 21-32 40-53 55z" fill="#fff" /></svg></a>
<a class="roundbutts youtubebutt" href="https://www.youtube.com/channel/UCo4XChVKgxIF4lJjfl4F5fQ"><svg width="37" height="30" viewBox="0 0 576 512"><path d="M500 67c-136-5-290-21-425 8-87 40-88 332 0 362 136 5 310 18 426 0 87-39 93-340-1-370zM232 338V175l143 81z" fill="#fff" /></svg></a>
<a class="roundbutts vkbutt" href="https://vk.com/lolzzz992q"><svg width="37" height="30" viewBox="0 0 576 512"><path d="M545 118c-9-25-89-23-102-5-17 39-35 135-100 138-21-22-10-96-10-133-1-35-124-27-124-8 23 11 42 165 13 166-20 0-69-73-98-157-3-37-105-35-105-6 39 127 121 299 272 303 79 2 24-95 58-96 56 3 80 95 128 96 25 0 88 7 79-25-133-177-59-84-11-273z" fill="#fff" /></svg></a>
<a class="roundbutts discordbutt" href="https://discord.com/users/249041263700082689"><svg width="37" height="30" viewBox="0 0 448 512"><path d="M271 272c-37-5-28-56 0-57 38 5 27 56 0 57zm-93-57c-38 4-27 56 0 57 37-5 28-56 0-57zM448 53v459c-64-57-44-38-119-108l14 48H52c-28 0-52-24-52-53V53C0 24 24 0 52 0h344c28 0 52 24 52 53zm-73 242c0-82-37-149-37-149-37-27-72-27-72-27l-3 4c43 14 63 33 63 33-64-36-137-45-206 0 0 0 21-20 67-34l-2-3s-35 0-72 27c0 0-37 67-37 149 0 0 22 38 78 39l17-21c-32-10-45-30-45-30 67 40 151 31 200 0 0 0-13 21-46 31l17 20c56-1 78-39 78-39z" fill="#fff" /></svg></a>
<a class="roundbutts plusbutt" href="https://blackinkeye.tk/site/Official-Pages"><svg width="37" height="30" viewBox="0 0 448 512"><path d="M416 208H272V64c-4-50-95-40-96 0v144H32c-50 4-40 95 0 96h144v144c4 50 95 40 96 0V304h144c50-4 40-95 0-96z" fill="#fff" /></svg></a>
</div>
<div class="readmore"><a href="https://blackinkeye.tk/site/Sofia" target="_self" style="margin-top: 40px;">{{ $t("message.main.2.readmore") }}</a></div>
</div>
</div>
</section> </div></div>
</template>