<template>
<section class="qwerybyaw" style="margin-bottom: -50px;">
<div class="col" id="timelineback">
<div class="col">
<h2 id="helloe-heading-title">{{ $t("message.main.4.timeline") }}</h2>
<div id="timeline">
<div class="timeline-item ">
<div class="timeline-icon">
<svg width="37" height="30" viewBox="0 0 384 512"><path d="M192 160c102-4 116-151 0-160a80 80 0 000 160zm-53 249l25-32-61-51c-22 28-71 72-47 106 23 31 63 101 104 72 33-36 2-64-21-95zm142-83l-61 51 25 32c-23 27-51 71-21 95 52 25 77-36 104-72 22-43-21-74-47-106zm96-181c-36-35-65-4-97 19-52 37-124 37-176 0-28-23-73-51-97-19-31 84 105 75 105 143h160c-12-61 150-72 105-143z" fill="#fff" /></svg>
</div>
<div class="timeline-content">
<h3>{{ $t("message.main.4.card1.header") }}</h3>
<p>{{ $t("message.main.4.card1.text") }}</p>
<p>{{ $t("message.main.4.card1.time") }}</p>
</div>
</div>
<div class="timeline-item">
<div class="timeline-icon">
<svg width="37" height="30" viewBox="0 0 512 512" class="fa-spin"><path d="M487 316l-42-25c4-23 4-47 0-70l42-25c19-23-38-121-64-111l-42 25c-18-15-39-27-61-35V26c-9-28-127-26-128 0v49c-22 8-43 20-61 35L89 86C59 77 4 187 25 196l42 25c-4 23-4 47 0 70l-42 25C6 339 63 437 89 427l42-25c18 15 39 27 61 35v49c10 28 127 26 128 0v-49c22-8 43-20 61-35l43 25c28 6 85-101 63-111zm-311-60c13-109 157-84 160 0-6 107-160 106-160 0z" fill="#fff" /></svg>
</div>
<div class="timeline-content">
<h3>{{ $t("message.main.4.card2.header") }}</h3>
<p>{{ $t("message.main.4.card2.text") }}</p>
<p>{{ $t("message.main.4.card2.time") }}</p>
</div>
</div>
<div class="timeline-item">
<div class="timeline-icon">
<svg width="37" height="30" viewBox="0 0 640 512"><path d="M622 153C348 34 290 35 18 153c-48 37 17 51 48 61a80 80 0 00-18 47c-20 15-21 39-2 53L20 429c0 35 92 27 88 0L82 314c19-17 18-41-2-53 1-15 9-28 21-37 213 96 250 92 521-25 24-7 24-39 0-46zM142 271l-14 113c51 92 376 88 384 0l-14-113c-142 64-215 57-356 0z" fill="#fff" /></svg>
</div>
<div class="timeline-content">
<h3>{{ $t("message.main.4.card3.header") }}</h3>
<p>{{ $t("message.main.4.card3.text") }}</p>
<p>{{ $t("message.main.4.card3.time") }}</p>
</div>
</div>
<div class="timeline-item">
<div class="timeline-icon">
<svg xmlns="http://www.w3.org/2000/svg" width="37" height="30" viewBox="0 0 496 512"><path fill="#FFF" d="M305 99l24 53-92 93a16 16 0 1022 22l93-92 53 24a21 21 0 0021-5l64-64c11-10 6-29-8-34l-56-18-18-56a20 20 0 00-34-8l-64 64a21 21 0 00-5 21zm78-49l13 38 5 15 15 5 38 13-45 45-49-22-22-49 45-45zm101 131l-26 26a216 216 0 11-426 49A216 216 0 01297 46l26-26a248 248 0 00-75-12 248 248 0 10236 173zm-209-72a53 53 0 01-3-15l-24-2a164 164 0 10164 164l-2-24-15-3-21-9c4 12 6 24 6 36a132 132 0 11-132-132c12 0 24 2 36 6l-9-21zm-27 67a80 80 0 1080 80l-1-11-45 45a48 48 0 11-68-68l45-45-11-1z" /></svg>
</div>
<div class="timeline-content">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398 160" style="margin-bottom: 20px"><path d="M398 160H0c0-11 5-22 14-29 47-37 99 2 135 6 86 11 168-70 242 5 4 5 7 11 7 18z" fill="#E8F1F8"/><path class="st1" d="M336 108c-1 5-4 9-9 9s-8-4-9-9c-2-16 5-53 9-53s11 39 9 53z"/><path class="st2" d="M327 90l-2 48 2 2 1-2-1-48z"/><path class="st3" d="M319 106c-1 6-5 11-11 11s-9-5-10-11c-3-16 7-65 10-65 4 0 15 42 11 65z"/><path class="st2" d="M308 79l-1 59 1 2h1l1-2-2-59z"/><path class="st1" d="M67 117c-1 2-3 5-5 5s-5-3-5-5c-2-9 3-30 5-30s6 22 5 30z"/><path class="st2" d="M62 107l-1 27 1 1v-28z"/><path class="st3" d="M47 116c0 3-3 6-6 6s-6-3-6-6c-2-9 4-37 6-37s8 24 6 37z"/><path class="st2" d="M41 100l-1 34 1 1 1-1-1-34z"/><path d="M270 128h-25c-2 0-3-2-3-4v-19l1-3 12-11c2-2 4-2 5 0l13 11 1 3v19c0 2-2 4-4 4z" fill="#E2CEB3"/><path fill="#FFF" d="M253 112h11v16h-11z"/><path d="M239 108l-1-1v-3l18-17h4l18 17v3h-3l-17-15-17 15-2 1z" fill="#57B7FF"/></svg>
<h3>{{ $t("message.main.4.card4.header") }}</h3>
<p>{{ $t("message.main.4.card4.text") }}</p>
<p>{{ $t("message.main.4.card4.time") }}</p>
</div>
</div>
</div>
</div></div>
</section>
</template>


<style>.st1{fill:#27CCC7}.st2{fill:#32393F}.st3{fill:#57D8DF}</style>